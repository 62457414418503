.App {
  text-align: center;
  margin: 20px;
}

button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
}

#result {
  margin-top: 20px;
}

#canvas {
  height: 600px;
  width: 600px;
  background-color: #fff;
  cursor: default;
  border: 1px solid black;
}
