@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700");
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.header-burger {
  display: none;
}

.dataCard {
  border-radius: 0.5em;
  /* padding: 1em; */
}

.revenueCard {
  width: 92%;
  height: 20rem;
}

.customerCard {
  width: 100%;
  margin: 0 auto;
  height: 20rem;
}

.categoryCard {
  width: 40%;
  height: 20rem;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}
.container-inner::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}
*::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #555;
}

/* carousel on about page */

.swiper {
  width: 100%;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  background: transparent;
}
.swiper-rapper {
  height: 100%;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 14px; /* Change to the desired width */
  height: 14px;
  background-color: #63676e;
}

.swiper-button-prev::before {
  /* content: <FaChevronUp />; Use imported icon */
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  height: 30px;
  width: 30px;
}

.swiper-button-next::before {
  /* content: <FaChevronDown />; Use imported icon */
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  height: 30px;
  width: 30px;
}

.swiper-button-prev,
.swiper-button-next {
  /* ... other styles ... */
  color: white; /* White text */
  padding: 10px;
  cursor: pointer;
  background-color: rgb(75, 73, 73);
  border-radius: 100px;
  /* Red background */
}
.swiper-button-disabled {
  background-color: black;
  opacity: 0.3;
  /* Red background */
  color: rgb(0, 0, 0); /* White text */
  cursor: pointer;
  border-radius: 100px;
}

.swiper-pagination-bullet-active {
  /* width: 20px; /* Change to the desired width */
  /* height: 20px; */
  outline: 3px solid rgba(255, 0, 0, 0.5); /* Adjust the width and color */
  background-color: #ff0000;
  border: none;
  transition: outline 0.2s ease; /* Adjust the transition property */
  margin: 4px;
  border-radius: 50%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-backdrop-filter {
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
}

.scrollbar {
  overflow-y: auto;
  /* Enable vertical scrolling */
  scrollbar-width: none;
  /* Hide the scrollbar in Firefox */
  -ms-overflow-style: none;
  /* Hide the scrollbar in IE and Edge */
}

.scrollbar::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar in Chrome, Safari, and Opera */
}

.slide-container {
  width: 100%; /* Adjust the width as needed */
  overflow: hidden;
}
.recharts-tooltip-cursor {
  background-color: rgb(37, 33, 33) !important;
  padding: 10px;
  border-radius: 10px;
  color: white !important;
}
.recharts-rectangle:hover {
  background-color: rgb(37, 33, 33) !important;
  padding: 10px;
  border-radius: 10px;
  color: white !important;
}
.recharts-rectangle {
  background-color: rgb(37, 33, 33) !important;
  color: white !important;
}
.custom-tooltip {
  background-color: rgb(37, 33, 33) !important;
  padding: 10px;
  border-radius: 10px;
  color: white !important;
}
.recharts-tooltip-cursor:hover {
  background-color: rgb(37, 33, 33) !important;
  padding: 10px;
  border-radius: 10px;
  color: white !important;
}
.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 50%; /* Display two slides at a time */
  padding: 0 5px;
}

.slide-small {
  flex: 0 0 100%; /* Display two slides at a time */
  padding: 0 5px;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Template */

.poster .main {
  background-size: contain;
  background-repeat: no-repeat;
  height: 924px;
  width: 650px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  min-height: fit-content !important;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 123px;
  font-size: 2em;
  padding: 0.5em;
  line-height: 1;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-repeat: no-repeat;
}

.box-e {
  background-image: linear-gradient(
      0deg,
      red,
      red 25%,
      transparent 25%,
      transparent 75%,
      red 75%
    ),
    linear-gradient(
      90deg,
      red,
      red 25%,
      transparent 25%,
      transparent 75%,
      red 75%
    ),
    linear-gradient(
      180deg,
      red,
      red 25%,
      transparent 25%,
      transparent 75%,
      red 75%
    ),
    linear-gradient(
      270deg,
      red,
      red 25%,
      transparent 25%,
      transparent 75%,
      red 75%
    );
  border-radius: 10px; /* Adjust the value as per your preference */
}

.poster h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 0;
}

.poster h1 {
  font-size: 52px;
  margin-bottom: 10px;
}

.poster .head {
  display: flex;
  gap: 50px;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

.poster .head span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.poster .head img {
  height: 40px;
  width: 40px;
}

.poster .middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.poster .middle img {
  height: 200px;
  width: 200px;
  margin: 50px 0 20px;
}

.poster .middle p {
  margin: 5px 0;
  width: 350px;
  text-align: center;
}

.poster .footer {
  color: maroon;
  text-transform: uppercase;
  margin-top: 20px;
}

.poster .footer p {
  margin: 0;
  text-align: center;
}

.poster .footer span {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid maroon;
  color: #fff;
  margin-top: 10px;
}
.logo_color {
  background: linear-gradient(45deg, #fdf497, #fd5949, #d6249f, #285aeb);
  color: linear-gradient(45deg, #fdf497, #fd5949, #d6249f, #285aeb);
}
.front .main {
  background: black;
  background-size: contain;
  background-repeat: no-repeat;
  height: 924px;
  width: 640px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.front h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 0;
}

.front h1 {
  font-size: 52px;
  margin-bottom: 10px;
  text-align: center;
  /* Added inline CSS to center the text */
}

.front .head {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

.front .head span {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.front .head img {
  height: 40px;
  width: 40px;
}

.front .middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.front .middle img {
  height: 200px;
  width: 200px;
  margin: 50px 0 20px;
  border-radius: 10%;
  /* Added inline CSS to apply border radius */
}

.front .middle p {
  margin: 5px 0;
  width: 350px;
  text-align: center;
}

.front .footer {
  color: maroon;
  text-transform: uppercase;
  margin-top: 20px;
}

.front .footer img {
  height: 40px;
  width: 40px;
}

.front .footer p {
  margin: 0;
  text-align: center;
}

.front .footer span {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid maroon;
  color: #fff;
  margin-top: 10px;
}
